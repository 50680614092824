import React from 'react';
import { Link } from 'gatsby';

import './Sidebar.scss';

const SidebarMenu = ({ show, menuLinks, click }) => {
  let sidebarClass = 'sideMenu';
  if (show) {
    sidebarClass = 'sideMenu open';
  }
  return (
    <div role="presentation" className={sidebarClass} onClick={click}>
      <div role="presentation" className="sideMenuClose" onClick={click}>
        X
      </div>
      <ul>
        {menuLinks.map(link => (
          <li key={link.name}>
            <Link to={link.link} activeClassName="current">
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarMenu;
