import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Hero from '../components/hero/Hero';
import Textblock from '../components/textblock/Textblock';

export default () => (
  <Layout>
    <Hero
      heading="Uh Oh! Something's gone"
      taglineText={['Wrooooooon', 'Wrongggg', 'Wrong!!@1!1']}
    />
    <Textblock textblockTitle="Lets try that again...">
      <p>
        <Link to="./">Click Here</Link>
        {' '}
to go back to the Home Page
      </p>
    </Textblock>
  </Layout>
);
