import React from 'react';
import { Link, navigate } from 'gatsby';
import SidebarToggleButton from './SidebarToggleButton';

import './Nav.scss';

const Nav = ({ headerTitle, menuLinks, sidebarClickHandler }) => (
  <nav>
    <h1>
      <Link to="./">{headerTitle}</Link>
    </h1>
    {menuLinks.map(link => (
      <button key={link.name} role="presentation" tabIndex="0">
        <Link to={link.link} activeClassName="current">
          {link.name}
        </Link>
      </button>
    ))}
    <SidebarToggleButton click={sidebarClickHandler} />
  </nav>
);

export default Nav;
