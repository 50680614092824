import React from 'react';
import menuToggle from '../../images/menuToggle.svg';

const SidebarToggleButton = ({ click }) => (
  <button className="menuToggle" onClick={click}>
    <img src={menuToggle} alt="menu toggle button" />
  </button>
);

export default SidebarToggleButton;
