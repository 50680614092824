import React, { Fragment, Children } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import SidebarMenu from './sidebar/Sidebar';
import Backdrop from './backdrop/Backdrop';
import Nav from './nav/Nav';
import Footer from './footer/Footer';

class Layout extends React.Component {
  static defaultProps = {
    data: '',
    site: '',
    siteMetadata: '',
    title: '',
    menuLinks: '',
    name: '',
    link: '',
  };

  state = {
    sidebarMenuOpen: false,
  };

  sidebarToggleHandler = () => {
    this.setState(prevState => {
      return { sidebarMenuOpen: !prevState.sidebarMenuOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sidebarMenuOpen: false });
  };

  sidebarClickHandler = () => {
    this.setState({ sidebarMenuOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sidebarMenuOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }

    const children = this.props.children;

    return (
      <Fragment>
        {backdrop}
        <SidebarMenu
          menuLinks={this.props.site.siteMetadata.menuLinks}
          show={this.state.sidebarMenuOpen}
          click={this.sidebarClickHandler}
        />
        <Nav
          sidebarClickHandler={this.sidebarToggleHandler}
          menuLinks={this.props.site.siteMetadata.menuLinks}
          headerTitle={this.props.site.siteMetadata.title}
        />
        {children}
        <Footer footerTitle={this.props.site.siteMetadata.title} />
      </Fragment>
    );
  }
}

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <Layout site={data.site}>{children}</Layout>}
  />
);
