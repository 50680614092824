import React from 'react';

import './Textblock.scss';

const Textblock = ({ textblockTitle, children }) => (
  <div className="textBlock">
    <h2>{textblockTitle}</h2>
    {children}
  </div>
);

export default Textblock;
