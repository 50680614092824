import React from 'react';

import './Hero.scss';

class Hero extends React.Component {
  static defaultProps = {
    heading: '',
    taglineText: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      isDeleting: false,
      loopNumber: 0,
      typeSpeed: 200,
    };
  }

  componentDidMount() {
    this.handleType();
  }

  handleType = () => {
    const { taglineText } = this.props;
    const { isDeleting, loopNumber, text, typeSpeed } = this.state;
    const i = loopNumber % taglineText.length;
    const fullTagline = taglineText[i];

    this.setState({
      text: isDeleting
        ? fullTagline.substring(0, text.length - 1)
        : fullTagline.substring(0, text.length + 1),
      typeSpeed: isDeleting ? 80 : 150,
    });

    if (!isDeleting && text === fullTagline) {
      setTimeout(
        () =>
          this.setState({
            isDeleting: true,
          }),
        500,
      );
    } else if (isDeleting && text === '') {
      this.setState({
        isDeleting: false,
        loopNumber: loopNumber + 1,
      });
    }

    setTimeout(this.handleType, typeSpeed);
  };

  render() {
    return (
      <header>
        <h2>
          {this.props.heading} {''} <span>{this.state.text}</span>
        </h2>
      </header>
    );
  }
}

export default Hero;
