import React from 'react';

import './Footer.scss';

const currentYear = new Date().getFullYear();

const Footer = ({ footerTitle }) => (
  <footer>
    <p>
      {footerTitle}
      {' '}
©
      {currentYear}
    </p>
  </footer>
);

export default Footer;
